.logo_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 50px;
    color: black;
    text-decoration: none;
}

.logo_container:hover, .logo_container:visited {
    color: black;
    text-decoration: none;
}

.logo {
    font-family: 'Pacifico', cursive;
    font-size: 30px;
}

.side_menu_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
}

.side_menu {
    width: 90%;
    height: 50px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    font-size: 18px;
    font-weight: bold;
    color: black;
    text-decoration: none;
    margin-bottom: 20px;
}

.side_menu:hover {
    cursor: pointer;
    background-color: #e7e7e7;
    text-decoration: none;
    color: black;
}

.side_menu_text {
    margin-left: 20px;
}

.profile_img {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border: 1px solid #c1c1c1;
    object-fit: cover;
}

@media (max-width: 1000px) {
    .side_menu_text, .logo_container {
        display: none;
    }
}

@media (max-width: 500px) {
    .side_menu_container {
        width: 100%;
        flex-direction: row;
    }
    .side_menu {
        width: 30px;
        /* display: none; */
    }
}
