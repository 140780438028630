.head {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #c1c1c1;
}

.body {
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: row;
}

.file_box {
    width: 60%;
    height: 100%;
    border-right: 1px solid #c1c1c1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.file {
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;
}

.arrow_left {
    position: absolute;
    background-color: #c1c1c1;
    margin-left: -56%;
    opacity: 0.8;
    z-index: 2;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
    border: none;
    cursor: pointer;
}

.arrow_right {
    position: absolute;
    background-color: #c1c1c1;
    margin-left: calc(60% - 40px);
    opacity: 0.8;
    z-index: 2;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
    border: none;
    cursor: pointer;
}

.body_content {
    width: 40%;
    height: 100%;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
}

.content_box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 20px 10px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #c1c1c1;
    flex-wrap: nowrap;
}

.profile_thumbnail {
    width: 40px;
    height: 40px;
    border: 1px solid #c1c1c1;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.profile_nickname {
    font-weight: bold;
    font-size: larger;
    margin-right: 20px;
    white-space: nowrap;
}

.content {
    word-break: break-all;
}

.chat_list {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.chat_box {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.chat_date {
    font-size: x-small;
    font-weight: bold;
}