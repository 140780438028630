.post_file_container {
    width: 60%;
    height: 100%;
    border-right: 1px solid #c1c1c1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.thumbnail_container {
    width: 100%;
    height: 100%;
    padding: 0 10px 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.thumbnail {
    width: 90%;
    height: 95%;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #c1c1c1;
}

.selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.selector:hover {
    cursor: pointer;
}