.sign_input_container {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #c1c1c1;
    background-color: white;
    padding: 30px 0 30px 0;
    margin-bottom: 20px;
}

.sign_nav_container {
    width: 350px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #c1c1c1;
    background-color: white;
    padding: 20px 0 20px 0;
}

.logo {
    font-family: 'Pacifico', cursive;
    font-size: 30px;
    margin-bottom: 30px;
}

.inputs {
    width: 70%;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 15px;
    border: 1px solid #c1c1c1;
}

.buttons {
    width: 70%;
    height: 35px;
    border-radius: 10px;
    border: none;
    background-color: #7c99ff;
    color: white;
    font-weight: bold;
    margin-top: 10px;
}

.nav_link {
    margin-left: 5px;
    font-weight: bold;
    color: #7c99ff;
}

.nav_link:hover {
    cursor: pointer;
}