.post_container {
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #c1c1c1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.post_body_container {
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.post_header_container {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #c1c1c1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    font-weight: bold;
}

.submit {
    color: #4848ff;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.submit:hover {
    cursor: pointer;
}

/* 로딩 애니메이션 스타일 */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
