.outstagram_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.sidebar_container {
    min-width: 250px;
    height: 100%;
    border-right: 1px solid #c1c1c1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    padding: 40px 0 20px 20px;
}

.main_container {
    width: 100%;
    height: 100%;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-wrap: nowrap;
}

@media (max-width: 1000px) {
    .sidebar_container {
        min-width: 90px;
    }
}

@media (max-width: 500px) {
    .outstagram_container {
        flex-direction: column;
    }
    .sidebar_container {
        width: 100%;
        height: 90px;
        flex-direction: row;
    }
}