.header {
    width: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 1px solid black;
    padding-top: 5px;
    margin-bottom: 5px;
}

.header_title {
    margin-left: 5px;
    font-weight: bold;
}
.container {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    justify-content: center;
    position: relative;
}

.row {
    width: 300px;
    height: 300px;
    margin-bottom: 30px;
    margin-right: 30px;
    border: 1px solid #c1c1c1;
    cursor: pointer;
    position: relative;
}

.image {
    width: 310px;
    height: 310px;
    object-fit: cover;
}

