.post_write_container {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20px
}

.header {
    height: 60px;
    padding-left: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header_thumbnail {
    width: 50px;
    height: 50px;
    border: 1px solid #c1c1c1;
    border-radius: 50%;
    object-fit: cover;
}

.header_nickname {
    margin-left: 15px;
    font-size: 20px;
    font-weight: bold;
}

.inputs {
    width: 100%;
    height: calc(100% - 60px);
    border: none;
    border-top: 1px solid #c1c1c1;
    padding: 10px 10px 10px 10px;
}
