.container {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.header {
    width: 80%;
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-bottom: 1px solid #c1c1c1;
}

.input {
    width: 100%;
    margin-right: 20px;
    border: none;
    background-color: #f8f8f8;
    padding-left: 20px;
    padding-right: 20px;
    font-size: larger;
    font-weight: bold;
    text-decoration: none;
}
