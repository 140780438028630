.container {
    width: 800px;
    height: 80%;
    background-color: white;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
}

.chat_room_list_container {
    width: 400px;
    height: 100%;
    border-right: 1px solid #c1c1c1;
    overflow: auto;
}

.list_container {
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #c1c1c1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.list_container:hover {
    background-color: #dadada;
}

.thumbnail {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #c1c1c1;
}

.content {
    width: 200px;
    height: 50px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.nickname {
    font-size: large;
    font-weight: bold;
}

.last_message {
    width: 100%;
    font-size: smaller;
    white-space: nowrap;
    overflow: hidden;
}

.date {
    margin-left: 20px;
    font-size: smaller;
    color: #646464;
}

.chat_room_container {
    width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.chat_body_container {
    width: 100%;
    height: 100%;
}

.input_container {
    width: 100%;
    height: 60px;
    border-top: 1px solid #c1c1c1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.input {
    width: 80%;
    height: 35px;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    padding: 10px;
}

