.profile_modify_container {
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #c1c1c1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.side_bar {
    width: 200px;
    height: 100%;
    border-right:  1px solid #c1c1c1;
    display: flex;
    flex-direction: column;
}

.link {
    color: black;
    text-decoration: none;
    height: 50px;
    padding-left: 20px;
    display: flex;
    align-items: center;
}

.link:visited {
    color: black;
    text-decoration: none;
}

.link:hover {
    background-color: #efefef;
    border-left: 2px solid gray;
}

.main {
    width: calc(100% - 250px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 50px
}