.chat_body_container {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #d0ddff;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
}

.input_container {
    width: 100%;
    height: 60px;
    border-top: 1px solid #c1c1c1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.input {
    width: 80%;
    height: 35px;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    padding: 10px;
}

.my_chat {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    margin-top: 10px;
    margin-bottom: 10px;
}

.oppo_chat {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.my_chat_box {
    border-radius: 5px;
    background-color: yellow;
    padding: 10px;
}

.oppo_chat_box {
    border-radius: 5px;
    background-color: white;
    padding: 10px;
}

.thumbnail {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #c1c1c1;
    object-fit: cover;
    margin-right: 10px;
}

.thumbnail_blank {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}