.post_chat_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.post_chat_header {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: end;
    font-weight: bold;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #c1c1c1;
}

.post_chat_body {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-top: 20px
}

.post_chat_input {
    width: 100%;
    height: 60px;
    border-top: 1px solid #c1c1c1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
}

.input {
    width: 100%;
    height: 100%;
    margin-right: 10px;
    padding-left: 20px;
}

.input, .input:hover, .input:active, .input:visited {
    border:none;
    outline: none;
}

.submit {
    width: 40px;
    font-weight: bold;
    color: #4848ff;
}

.submit:hover {
    cursor: pointer;
}

.chat_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 20px;
    padding-right: 20px
}

.chat_thumbnail {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border: 1px solid #c1c1c1;
    border-radius: 50%;
    margin-right: 10px;
}

.chat_date {
    font-size: x-small;
    font-weight: bold;
}